<template>
	<div>
		<CRow>
			<CCol md="12">
				<CCard>
					<CCardHeader>
						<CRow>
							<CCol md="8">
								<h4>Edit File Download</h4>
							</CCol>
							
						</CRow>
					</CCardHeader>
					<CForm v-on:submit.prevent="storeProcess" class="fwdwd">
						<CCardBody>
							<div class="form-group">
								<label for=""><b>Nama File <span class="text-danger">*</span></b></label>
								<input type="text" class="form-control" placeholder="Nama File" v-model="nama">
							</div>
							<div class="form-group">
								<label for=""><b>Komoditas <span class="text-danger">*</span></b></label>
								<v-select :options="list_kategori_file_download" v-model="komoditas"></v-select>
							</div>
							<div class="form-group form-check">
								<input type="checkbox" class="form-check-input" id="exampleCheck1" v-model="is_modal_tanaman">
								<label class="form-check-label" for="exampleCheck1">Modal Jenis Tanaman</label>
							</div>
							<div class="form-group" v-if="!is_modal_tanaman">
								<label for=""><b>File Download</b></label>
								<input type="file" class="form-control mb-0" id="files-doc" v-on:change="inputFileDocument()">
								<div class="respond-input-file float-left" id="docloading">
									<a v-if="real_path" :href="real_path" target="_blank" style="margin-bottom:10px;"><span class="badge badge-success p-1"><i class="fa fa-eye"></i> Lihat Dokumen</span></a>
								</div>
								<small class="float-right">*) Max File 2 MB</small>
							</div>
							<div class="form-group" v-if="!is_modal_tanaman">
								<label for=""><b>File Download (External Website)</b></label>
								<input type="text" class="form-control" placeholder="File Download (External Website)" v-model="path">
							</div>
							<div>
								<div class="alert alert-info">
									<i class="fa fa-info"></i> Isi hanya salah satu field file download.
								</div>
							</div>
						</CCardBody>
						<CCardFooter>
							<div class="text-right">
								<button type="submit" class="btn btn-primary text-white btn-hover-outline-primary"><i class="fa fa-save"></i> Simpan</button>
							</div>
						</CCardFooter>
					</CForm>
				</CCard>
			</CCol>
		</CRow>
	</div>
</template>
<script>
	export default {
		name: "EditFileDownload",
		data() {
			return {
				nama: '',
				komoditas: '',
				is_modal_tanaman: false,
				path: '',
				real_path: '',
				file_download_id: this.$route.params.file_download_id,
				list_kategori_file_download: ['Hortikultura', 'Perkebunan', 'Tanaman Pangan', 'Sumber Daya Genetik']
			};
		},
		methods: {
			inputFileDocument(){
				let self = this;
				if ($('#files-doc')[0].files[0]) {
					if ($('#files-doc')[0].files[0].size < 2242880) {
						$('#docloading').html('<span class="badge badge-warning p-1"><i class="fa fa-refresh fa-spin"></i> Loading...</span>');
						var urlres = this.apiLink;
						var FormData = require('form-data');
						var data = new FormData();
						data.append('file', $('#files-doc')[0].files[0]);
						var config = {
						  method: 'post',
						  url: this.apiLink+'api/crud/file_download/upload',
						  headers: { 
						    Authorization: "Bearer " + this.access_token,
						  },
						  data : data
						};
						axios(config)
						.then(function (response) {
							
							urlres += response.data.data.url;
							$('#docloading').html('<span class="badge badge-success p-1"><i class="fa fa-check"></i> Berhasil </span> <a href="'+urlres+'" target="_blank" style="padding-left:10px;"><span class="badge badge-success p-1"><i class="fa fa-eye"></i> Lihat Dokumen</span></a>');
							self.path = urlres;
						});
						// console.log(this.permohonan_dokumen);
					}
					else{
						alert('Max File 2 MB');
					}
				}
			},
			storeProcess: function () {
				let self = this;
				Swal.fire({
					title: '<i class="fa fa-refresh fa-spin"></i>',
					text: "Loading...",
					showConfirmButton: false,
					allowOutsideClick: false,
				});
				var as_path = this.path;
				if(!this.path){
					as_path = this.real_path;
				}
				axios.put(
                    this.apiLink + "api/crud/file_download",
                    {
						id: this.file_download_id,
                        nama: this.nama,
                        komoditas: this.komoditas,
						is_modal_tanaman: this.is_modal_tanaman,
						path: as_path,
                        
                    },
                    {
                        headers: {
                            Authorization: "Bearer " + this.access_token,
                        },
                    }
                )
				.then(function (response) {
					var res_data = response.data;
					// console.log(res_data);
					if (res_data.meta.code != "200") {
						Swal.fire({
							icon: 'error',
							title: 'Oops...',
							text: res_data.data.message
						});
					}
					else{
						let timerInterval;
						Swal.fire({
							icon: "success",
							title: "Berhasil",
							text: "Anda akan diarahkan ke halaman master file download segera",
							timer: 2000,
							timerProgressBar: true,
							showCancelButton: false,
							showConfirmButton: false,
							didOpen: () => {
								timerInterval = setInterval(() => {
									const content = Swal.getContent();
									if (content) {
										const b = content.querySelector("b");
										if (b) {
											b.textContent = Swal.getTimerLeft();
										}
									}
								}, 100);
							},
							willClose: () => {
								clearInterval(timerInterval);
							},
						}).then((result) => {
							/* Read more about handling dismissals below */
							if (result.dismiss === Swal.DismissReason.timer) {
								self.$router.push({name: 'AllFileDownload'});
							}
						});
					}
				});
			},
		},
		created() {
            Swal.fire({
                title: '<i class="fa fa-refresh fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
                allowOutsideClick: false,
            });
           
            axios.get(this.apiLink + "api/crud/file_download", {
                params: {
                    id: this.file_download_id,
                },
                headers: {
                    Authorization: "Bearer " + this.access_token,
                },
            })
            .then((response) => {
                var res_file_download = response.data;
                var res_file_download_data = res_file_download.data[0].data[0];
                if (res_file_download.meta.code != "200") {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: res_file_download.data.message
                    });
                }
                else{
                    Swal.close();
					this.nama = res_file_download_data.nama;
					this.komoditas = res_file_download_data.komoditas;
					this.is_modal_tanaman = res_file_download_data.is_modal_tanaman;
					this.real_path = res_file_download_data.path;
					this.path = res_file_download_data.path;
                }
            });
        }
	};
</script>